@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Thin.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Light.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Medium.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Black.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Regular.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Bold.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-weight: regular;
  font-size: 22px;
  line-height: 37px;
  overflow-x: hidden;
}

@media screen and (max-width: 800px) {
  body {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  body {
    font-size: 18px;
  }
}
$primary-color: hsl(183, 74%, 40%);
$primary-color-hover: hsl(183, 74%, 50%);
$scroll-width: 20px;

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px $primary-color;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color-hover;
}

/**
  * Removes the default scrollbar of body
  */
@-moz-document url-prefix() {
  /* Disable scrollbar Firefox */
  html {
    scrollbar-width: $scroll-width;
  }
}

body {
  margin: 0;
  scrollbar-width: $scroll-width;
  -ms-overflow-style: none;
  overflow-y: auto !important;
  overflow-x: auto !important;
}

body::-webkit-scrollbar {
  width: $scroll-width;
  height: $scroll-width;
  background: transparent;
  transition: all 0.3s ease-in-out;
}

::selection {
  background-color: $primary-color;
  color: #ffffff;
}

.m-y-20 {
  margin: 20px 0px;
}

.mySwiper .swiper-button-next {
  background-image: url(./assets/svgs/arrow-circle-left-white.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.mySwiper .swiper-button-next::after {
  display: none !important;
}

.mySwiper .swiper-button-prev {
  background-image: url(./assets/svgs/arrow-circle-right-white.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.mySwiper .swiper-button-prev::after {
  display: none !important;
}

@media print {
  .no-print {
    display: none;
  }

  .hidden {
    visibility: hidden;
  }

  .print {
    display: block !important;
  }
}

/**
 * Disable video and audio download 
 */

video::-internal-media-controls-download-button {
  display: none !important;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}

audio::-internal-media-controls-download-button {
  display: none !important;
}

audio::-webkit-media-controls-enclosure {
  overflow: hidden !important;
}

audio::-webkit-media-controls-panel {
  width: calc(100% + 30px) !important;
}
